import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useState, useEffect, useRef } from "react";

import { useNavigate } from "react-router-dom";
import { GET_ALTERNATIVE_EV_BRANDS, GET_SIMULATION_PREFERENCE } from "../../../../../../services/graphql/fleet/queries/simulation";
import { useArrayOutsideClickDetector } from "../../../../../../electrify_frontend_common/hooks/useOutsideClickAlerter";
import { UPDATE_FLEET_SIMULATION_PREFERENCES } from "../../../../../../services/graphql/fleet/mutations/simulation";
import { TemperatureSlider } from "../../../../../../common/components/misc/TemperatureSlider";
import { MakeDropdown } from "../../../../../../electrify_frontend_common/components/EvFinder/Versions[A-B]/Filters/components/MakeDropdown";

const {
  Select,
  MenuItem,
  Checkbox,
  ListItemText
} = require("@mui/material");

const PreferencesSection = ({
  buttonRef,
  onApply,
  close,
}: {
  buttonRef: any,
  onApply: (p: any) => void;
  close: () => void;
}) => {
  const { t } = useTranslation("fleetSimulation");
  const navigate = useNavigate();

  const [getPreferences] = useLazyQuery(GET_SIMULATION_PREFERENCE, {
    onCompleted: (data) => {
      const updatedPreferences = data?.simulationPreference;
      updateLocalPreferences(updatedPreferences);
    },
    onError: (err) => console.log("ERROR: ", err),
  });

  const [availableBrands, setAvailableBrands] = useState([]);

  const [brands, setBrand] = useState<any>([]);
  const [temperature, setTemperature] = useState<number>(0);

  const ref = useRef<any>(null);

  const makeDropdownRef = useRef<HTMLDivElement>(null);

  useArrayOutsideClickDetector([ref, buttonRef, makeDropdownRef], close);

  useQuery(GET_ALTERNATIVE_EV_BRANDS, {
    onCompleted: (data) => setAvailableBrands(data?.alternativeEvBrands),
    onError: (err) => console.log("ERRRR>>>", err),
  });

  useEffect(() => {
    getPreferences();
  }, []);

  const updateLocalPreferences = (updatedPreferences: any) => {
    setBrand(updatedPreferences?.selectedBrands || []);
    setTemperature(updatedPreferences?.setTemperature);
  };

  const [updatePreferences] = useMutation(UPDATE_FLEET_SIMULATION_PREFERENCES, {
    onCompleted: (data) => {
      const updatedPreferences = data?.updateFleetSimulationPreferences;
      updateLocalPreferences(updatedPreferences);
      onApply(updatedPreferences);
    },
    onError: (err) => console.log("Error: ", err),
  });

  const handleUpdatePreferences = ({
    brands,
    temperature,
  }: {
    brands?: any;
    temperature?: number;
  }) => {
    // TODO verify if preferences have actually changed
    let brandsSelected = [];

    if (brands.includes("All") && brands.length === 1) {
      brandsSelected = [];
    } else if (brands.includes("All") && brands.length > 1) {
      brandsSelected = availableBrands.filter(
        (item: any) => !brands.includes(item)
      );
    } else {
      brandsSelected = brands;
    }

    updatePreferences({
      variables: {
        preference: {
          setTemperature: temperature,
          selectedBrands: brandsSelected,
        },
      },
    });
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  // const handleOnChange = (value: any) => {

  //   if (value.includes("All") && !brands.includes("All")) {
  //     setBrand(["All"]);
  //   } else if (!value.includes("All") && brands.includes("All")) {
  //     setBrand([]);
  //   } else {
  //     setBrand(typeof value === "string" ? value.split(",") : value);
  //   }
  // };

  return (
    <div ref={ref} className="w-96 border border-Grey-tint rounded z-30 overflow-hidden absolute p-6 bg-white top-16 ">
      <h4 className="text-sm text-Blueberry-dark-default font-medium mb-4">
        {t("preferences.title")}
      </h4>
      <h5 className="text-sm mb-2 text-Grey-shade">
        {t("preferences.header1")}
      </h5>
      <p className="text-sm text-Grey-shade mb-4">
        {t("preferences.text1-before-page-name")}{" "}
        <span
          onClick={() => {
            close();
            navigate("settings/simulation");
          }}
          className="underline font-medium text-Blueberry-dark-default cursor-pointer"
        >
          {t("preferences.text1-page-name")}
        </span>{" "}
        {t("preferences.text1-after-page-name")}
      </p>
      <hr className="border-solid border-Grey-tint mb-4" />
      <h5 className="text-sm text-Grey-shade mb-2">
        {t("preferences.header-brands")}
      </h5>

    <MakeDropdown
      value={brands}
      updateValue={(value: string[] | null) => {
        setBrand(value);
      }}
      backgroundColor="light"
      ref={makeDropdownRef}
    />
      <h5 className="text-sm text-Grey-shade mb-2">
        {t("preferences.header-temperature")}
      </h5>
      <TemperatureSlider
        temperature={temperature}
        setTemperature={setTemperature}
        saveTemperature={()=> null}
      />

      <hr className="border-solid border-Grey-tint mb-9 mt-4" />

      <button
        className="p-5 bg-Blueberry-light-shade text-white rounded text-base w-full border border-Blueberry-light-shade"
        onClick={() => handleUpdatePreferences({ brands, temperature })}
      >
        {t("preferences.apply")}
      </button>
    </div>
  );
};

export default PreferencesSection;
