import { useTranslation } from "react-i18next";
import { useUserData } from "../../../../../../hooks/state/useUserData";
import ModalWrapper from "../../../../../../electrify_frontend_common/components/ModalWrapper";
import SimpleModalHeader from "../../../../../../electrify_frontend_common/components/ModalWrapper/Headers";
import { FileUploader } from "../../../../../../common/components/FileUploader";
import { Button } from "../../../../../../electrify_frontend_common/components/Buttonv2";
import { ButtonSize, ButtonVariant } from "../../../../../../electrify_frontend_common/components/Buttonv2/types";


export function DriverTripUploadModal({
  isOpen,
  close,
}: {
  isOpen: boolean;
  close: () => void;
}) {
  const { t } = useTranslation("driverOnboarding");
  const { refreshUserAccountInfo } = useUserData();

  return (
    <ModalWrapper isOpen={isOpen} close={close}>
      <div className="w-[1000px]">
        <SimpleModalHeader
          title={t("step-upload.button-text")}
          onCancel={() => {
            refreshUserAccountInfo();
            close();
          }}
        />
        <div className="p-10">
          <div className="text-xl mb-4">Vehicle tracking data</div>
          <div className="text-sm text-Grey-dark w-2/3 mb-10">
            Please upload your data in form of a CSV file. This process will
            take 1-2 minutes, depending on your file size. We will inform you
            when everything is uploaded.
          </div>

          <FileUploader
            successElement={
              <div className="flex w-full items-center justify-end mt-4">
                <Button
                  variant={ButtonVariant.PRIMARY}
                  size={ButtonSize.LARGE}
                  onClick={() => {
                    close();
                  }}
                >
                  Done
                </Button>
              </div>
            }
          />
        </div>
      </div>
    </ModalWrapper>
  );
}
