import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { TrackingData } from "./TrackingData";
import { PublicCharging } from "./PublicCharging";
import { ElectrificationPlanner } from "./ElectrificationPlanner";
import { useQuery } from "@apollo/client";

import { EvScore } from "./EvScore";
import { ElectrificationProgress } from "./ElectrificationProgress";
import { OnboardingBanner } from "../OnboardingPage/OnboardingBanner";
import { useLayout } from "../../../../../hooks/state/useLayout";
import { VehicleWithYearlyStats } from "../../../../../@types/vehicle";
import { GET_DRIVER_VEHICLE } from "../../../../../services/graphql/driver/queries";
import { ElectrifyPlannerContextProvider } from "../../../contexts/ElectrificationPlannerContext";
import { Spinner } from "../../../../../electrify_frontend_common/components/Spinner";
import { FutureVehicle } from "./FutureVehicleCard";


export function Overview() {

  const { t, i18n } = useTranslation("driverOverview");
  const language = i18n.language;

  const { setHeader, currencySymbol, localeCode } = useLayout();

  useEffect(() => {
    setHeader({
      defaultHeaderTitle: t("title"),
      defaultHeaderDescription: t("description"),
    });
  }, [language]);

  const { data } = useQuery<{ driverVehicle: VehicleWithYearlyStats }>(
    GET_DRIVER_VEHICLE
  );

  return (
    <>
      <div>
        <OnboardingBanner />

        <section className="p-10 flex flex-col h-full w-full">
          <div className="grid grid-cols-4 w-full h-fit">
            <div className="flex w-full col-span-3">
              <div className="pr-14 w-1/2">
                <EvScore />
              </div>
              <div className="w-1/2 pr-14">
                {data?.driverVehicle ? (
                  <FutureVehicle
                    currencySymbol={currencySymbol}
                    localeCode={localeCode}
                    vehicle={data?.driverVehicle} />
                ) : (
                  <Spinner />
                )}
              </div>
            </div>
            <div className="col-span-1">
              <ElectrifyPlannerContextProvider>
                <ElectrificationProgress />
              </ElectrifyPlannerContextProvider>
            </div>
          </div>
          <div className="h-full mt-11 pb-10">
            <div className="grid grid-cols-4 w-full h-fit">
              <div className="flex w-full col-span-3">
                <div className="pr-14 w-1/2">
                  {data?.driverVehicle ? (
                    <TrackingData vehicle={data?.driverVehicle} />
                  ) : (
                    <Spinner />
                  )}
                </div>
                <div className="w-1/2 pr-14">
                  <PublicCharging vehicleId={data?.driverVehicle?.id} />
                </div>
              </div>
              <div className="col-span-1">
                <ElectrificationPlanner />
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
