import { useQuery } from "@apollo/client";
import { useEffect, useMemo, useState } from "react";
import { Vehicle } from "../../../../../../@types/vehicle";
import { GET_TRIPS_AMOUNT_AND_DISTANCES } from "../../../../../../services/graphql/driver/queries";
import { useLayout } from "../../../../../../hooks/state/useLayout";
import { GET_VEHICLE_VALID_ANALYSIS_PERIOD } from "../../../../../../services/graphql/fleet/queries";



export function useTrackingData(vehicle: Vehicle) : {
    validPeriod: any,
    analysisPeriod: any,
    tripsAmountData?: {
        countOfTrips: number,
        totalDistance: number,
        yearlyMileage: number
    }
} {

    const [validPeriod, setValidPeriod] = useState<{ min?: string, max?: string } | null>(null);
    const [analysisPeriod, setAnalysisPeriod] = useState<{ min?: string | null, max?: string | null } | null>(null);

    const {data: tripsAmountData} = useQuery(GET_TRIPS_AMOUNT_AND_DISTANCES);

    const {localeCode} = useLayout()

    const defaultDate = useMemo(()=> {
        return new Date().toLocaleString(localeCode).split('T')[0].split(', ')[0];
    },[])


    const {refetch} = useQuery(GET_VEHICLE_VALID_ANALYSIS_PERIOD, {
        variables: {
            id: vehicle.id
        },
        onCompleted: (response) => {
            const data = response.vehicleSummary;
            const minSelectableDate = data.earliestTripDate?.split('T')[0] || defaultDate;
            const maxSelectableDate = data.latestTripDate?.split('T')[0] || defaultDate;
            setAnalysisPeriod({ min: vehicle?.analysisPeriod?.from == null ? null : vehicle?.analysisPeriod?.from?.split("T")[0], max: vehicle?.analysisPeriod?.to === null ? null : vehicle?.analysisPeriod?.to?.split("T")[0] })
            setValidPeriod({ min: minSelectableDate, max: maxSelectableDate });
        }
    })

    useEffect(()=> {
        refetch();
    },[vehicle])


    return {
        validPeriod,
        analysisPeriod,
        tripsAmountData : tripsAmountData?.tripsAmountAndDistances
    }



}