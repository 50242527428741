import { Slider } from "@mui/material";
import { useMemo } from "react";

export enum LoadWeightEnum {
    LOW = "LOW",
    MEDIUM = "MEDIUM",
    HIGH = "HIGH",
    FULL = "FULL",
}

const enumToIndex = {
    [LoadWeightEnum.LOW]: 0,
    [LoadWeightEnum.MEDIUM]: 1,
    [LoadWeightEnum.HIGH]: 2,
    [LoadWeightEnum.FULL]: 3,
};

const indexToEnum = Object.keys(enumToIndex).reduce((acc, key) => {
    acc[enumToIndex[key as LoadWeightEnum]] = key as LoadWeightEnum;
    return acc;
}, {} as { [key: number]: LoadWeightEnum });

interface LoadWeightSliderProps {
    loadWeight: LoadWeightEnum;
    setLoadWeight: (v: LoadWeightEnum) => void;
    saveLoadWeight: () => void;
}

export function LoadWeightSlider({ loadWeight, setLoadWeight, saveLoadWeight }: LoadWeightSliderProps) {

    const marks = useMemo(() => [
        { value: enumToIndex[LoadWeightEnum.LOW], label: LoadWeightEnum.LOW },
        { value: enumToIndex[LoadWeightEnum.MEDIUM], label: LoadWeightEnum.MEDIUM },
        { value: enumToIndex[LoadWeightEnum.HIGH], label: LoadWeightEnum.HIGH },
        { value: enumToIndex[LoadWeightEnum.FULL], label: LoadWeightEnum.FULL },
    ], []);

    return <Slider
        aria-label="Custom marks"
        value={enumToIndex[loadWeight]}
        step={1}
        valueLabelDisplay="auto"
        marks={marks}
        min={0}
        max={3}
        sx={{
            "& .MuiSlider-thumb": { color: "#830751" },
            "& .MuiSlider-rail": { color: "#ccc" },
            "& .MuiSlider-mark": { display: "none" },
            "& .MuiSlider-track": { opacity: 0 },
        }}
        onChange={(e, value) => {
            const enumValue = indexToEnum[value as number] as LoadWeightEnum;
            setLoadWeight(enumValue);
        }}
        onChangeCommitted={() => saveLoadWeight()}
    />
}
