import { useState } from "react";

import { InformationBanner } from "../utils/InformationBanner";
import { NextButton } from "../utils/NextButton";
import { TrackingData } from "../../Overview/TrackingData";
import { useTranslation } from "react-i18next";
import { DriverTripUploadModal } from "../utils/DriverTripUploadModal";
import { VehicleWithYearlyStats } from "../../../../../../@types/vehicle";
import { useUserData } from "../../../../../../hooks/state/useUserData";
import { Button } from "../../../../../../electrify_frontend_common/components/Buttonv2";
import { ButtonSize, ButtonVariant } from "../../../../../../electrify_frontend_common/components/Buttonv2/types";

export function DriverDataUpload({
  step,
  nextStep,
  vehicle,
  refetchVehicle,
}: {
  step: number;
  nextStep: () => void;
  vehicle?: VehicleWithYearlyStats;
  refetchVehicle: any;
}) {
  const { t } = useTranslation("driverOnboarding");

  const [uploadOpen, setUploadOpen] = useState(false);
  const { user } = useUserData();

  return (
    <>
      {uploadOpen ? (
        <DriverTripUploadModal
          isOpen={uploadOpen}
          close={() => setUploadOpen(false)}
        />
      ) : null}
      <div className="p-10 w-[1000px]">
        <div className="text-xl">
          <span className="text-Blueberry-dark-default ">
            {t("step")} {step}:
          </span>{" "}
          {t("step-upload.title-1")}{" "}
          <span className="font-bold">{t("step-upload.title-2")}</span>{" "}
          {t("step-upload.title-3")}
        </div>
        <div className="flex w-5/6 text-sm text-Grey-dark py-2">
          {t("step-upload.message")}
        </div>
        {!user?.accountInfo?.usableProduct || !vehicle ? (
          <div className="flex bg-Grey-background justify-center w-full mt-2 py-20">
            <Button
              variant={ButtonVariant.PRIMARY}
              size={ButtonSize.LARGE}
              onClick={() => setUploadOpen(true)}
            >
              {t("step-upload.button-text")}
            </Button>
          </div>
        ) : (
          <div className="w-5/12">
            <TrackingData vehicle={vehicle} hideTitle />
          </div>
        )}
        {!vehicle ? (
          <InformationBanner tooltipContent="Upload information" />
        ) : null}

        <div className="flex justify-end mt-10">
          <NextButton
            active={!!user?.accountInfo?.usableProduct}
            text={user?.accountInfo?.usableProduct ? t("next") : t("skip")}
            onClick={nextStep}
          />
        </div>
      </div>
    </>
  );
}
