import { useState } from "react";
import { ReactComponent as InformationIcon } from "../../../../../../assets/information-icon-blueberry.svg";
import { useMutation, useQuery } from "@apollo/client";

import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { Tooltip } from "../../../../../../electrify_frontend_common/components/Tooltip";
import Dropdown from "../../../../../../electrify_frontend_common/components/Dropdown";
import { GET_VEHICLE_SIMULATION_PREFERENCES } from "../../../../../../services/graphql/driver/queries";
import { UPDATE_VEHICLE_SIMULATION_PREFERENCES } from "../../../../../../services/graphql/driver/mutations";
import { TemperatureSlider } from "../../../../../../common/components/misc/TemperatureSlider";
import { LoadWeightSlider, LoadWeightEnum } from "../../../../../../common/components/misc/LoadWeightSlider";
import { ExpandIcon } from "../../../../../../electrify_frontend_common/icons/ExpandIcon";


export function SocBuffer({
    socBuffer,
    setSocBuffer,
}: {
    socBuffer: number;
    setSocBuffer: (v: number) => void;
}) {
    const { t } = useTranslation("driverSettings");
    const socOptions = [0, 5, 10, 15, 20];

    return (
        <div className="border flex  items-center border-Grey-default rounded py-3 px-6 justify-between">
            <div className="flex items-center">
                <span className="text-Grey-dark font-bold text-sm">
                    {t("soc-buffer")}
                </span>
                <Tooltip
                    content={<div className="w-52">{t("soc-buffer-tooltip")}</div>}

                    placement="top-start"
                >
                    <InformationIcon className="ml-1" />
                </Tooltip>
            </div>
            <Dropdown
                loadUpwards
                icon={<ExpandIcon />}
                buttonClassName="py-1 ml-1"
                className="w-24 border text-sm rounded border-Grey-default"
                placeholder={`${socBuffer}%`}
                data={socOptions.map((so, i) => ({
                    key: (
                        <div className="text-sm border-b-Grey-default">
                            {so} %
                        </div>
                    ),
                    onClick: () => setSocBuffer(so),
                }))}
            />
        </div>
    );
}



export function ChargingPreferences() {
    const { t } = useTranslation("driverSettings");

    const [socBuffer, setSocBuffer] = useState(5);
    const [setTemperature, setSetTemperature] = useState<number>(0);
    const [setLoadWeight, setSetLoadWeight] = useState<LoadWeightEnum>(LoadWeightEnum.LOW);

    const { refetch } = useQuery(GET_VEHICLE_SIMULATION_PREFERENCES, {
        onCompleted: (data) => {
            const prefs = data.vehicleSimulationPreferences;
            setSocBuffer(prefs.socBuffer || 0);
            setSetTemperature(prefs.setTemperature || 0);
            setSetLoadWeight(prefs.setLoadWeight || LoadWeightEnum.LOW);
        },
    });

    const [updateSimulationMutation] = useMutation(
        UPDATE_VEHICLE_SIMULATION_PREFERENCES, {
        onCompleted: () => {
            toast.success("Successfully updated vehicle's simulation preferences");
            refetch();
        },
        onError: () => {
            toast.error("Failed to update vehicle simulation preferences");
            refetch();
        }
    }
    );

    return (
        <div className="flex w-full mt-10">
            <div className="grid grid-cols-3 gap-6 w-full">
                <div className="flex flex-col col-span-1">
                    <div className="w-full">
                        <div className="text-lg mb-6">
                            {t("charging-preferences")}
                        </div>
                        <SocBuffer
                            socBuffer={socBuffer}
                            setSocBuffer={(v: number) => {
                                setSocBuffer(v);
                                updateSimulationMutation({
                                    variables: {
                                        vehicleSimulationPreferences: {
                                            socBuffer: v,
                                            setTemperature,
                                            setLoadWeight
                                        },
                                    },
                                });
                            }}
                        />
                    </div>
                </div>

                <div className="col-span-1">
                    <div className="w-full">
                        <div className="text-lg mb-6">{t("temperature")}</div>
                        <TemperatureSlider
                            temperature={setTemperature}
                            setTemperature={(v: number) => setSetTemperature(v)}
                            saveTemperature={() => updateSimulationMutation({
                                variables: {
                                    vehicleSimulationPreferences: {
                                        socBuffer,
                                        setTemperature,
                                        setLoadWeight
                                    }
                                },
                            })}
                        />
                    </div>
                </div>

                <div className="col-span-1">
                    <div className="w-full">
                        <div className="text-lg mb-6">Load Weight</div>
                        <LoadWeightSlider
                            loadWeight={setLoadWeight}
                            setLoadWeight={(v: LoadWeightEnum) => setSetLoadWeight(v)}
                            saveLoadWeight={() => updateSimulationMutation({
                                variables: {
                                    vehicleSimulationPreferences: {
                                        socBuffer,
                                        setTemperature,
                                        setLoadWeight
                                    }
                                },
                            })}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
