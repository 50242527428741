import { Tariff } from "../../../../../../../../@types/paymentProcess";
import { useContext, useMemo } from "react";
import { ElectrifySubProducts } from "../../../../../../../../enums";
import { DriverDongleProductInfo } from "./DriverDongleProductInfo";
import { DriverDataUploadProductInfo } from "./DriverDataUploadProductInfo";
import { DriverPaymentGatewayContext } from "../../../../../../contexts/DriverPaymentGatewayContext";


export function ProductInfo({ tariff }: { tariff: Tariff }) {

    const { usedVoucher } = useContext(DriverPaymentGatewayContext);

    // TODO change this approach to identidy the first value to pay in another way
    const price = tariff.tariffPrices.sort((a, b) => b.price - a.price)[0]
        .price;

        
    const ProductInfoContent = useMemo(() => {
        const productName = tariff.product.name;
        switch (productName) {
            case ElectrifySubProducts.DRIVER_DONGLE:
                return <DriverDongleProductInfo price={price} usedVoucher={usedVoucher} currencySymbol={tariff.tariffPrices[0]?.invoiceCurrency?.symbol} />;
            case ElectrifySubProducts.DRIVER_DATA_UPLOAD:
                return <DriverDataUploadProductInfo price={price} usedVoucher={usedVoucher} currencySymbol={tariff.tariffPrices[0]?.invoiceCurrency?.symbol} />;
            default:
                return "Unavailable product name";
        }
    }, [tariff.product.name, usedVoucher, price, tariff.tariffPrices])

    return (
        <div className="flex flex-col bg-Grey-background h-full p-10 px-24 pl-48 justify-between">
            {ProductInfoContent}
        </div>
    );
}
