import { ReactElement, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as DeleteFileIcon } from "../../../assets/delete-file-icon.svg";
import { EventBanner, EventState } from "../misc/EventBanner";
import { Tooltip } from "../../../electrify_frontend_common/components/Tooltip";


const UploadResultWidget = ({ className, text, tooltipContent }: { className: string, text: string, tooltipContent: ReactElement }) => {

    return (
        <Tooltip
            content={<div>{tooltipContent}</div>}
            placement="top-start"
        >
            <div className={`${className} py-2 px-4 bg-opacity-20 rounded flex items-center`}>
                {text}
            </div>
        </Tooltip>
    )
}

export function FilesDisplay({ files, removeFile, failedFiles, successfulFiles, uploading }:
    { files: File[], removeFile: (i: number) => void, failedFiles: { code: string, cause: string }[], successfulFiles: string[], uploading: boolean }) {


    const { t } = useTranslation("dataProviderUpload");

    const failedCodes = failedFiles.map((file: any) => file.fileCode);
    const allFilesFailed = Object.values(files).length > 0 && failedCodes.length == Object.values(files).length;
    const allFilesSucceeded = Object.values(files).length > 0 && successfulFiles.length == Object.values(files).length;
    const someFilesFailed = successfulFiles.length > 0 && failedCodes.length > 0;

    const isFileSuccessful = (file: any) => successfulFiles.includes(file.code);
    const isFileFailed = (file: any) => failedCodes.includes(file.code);


    const bannerText = useMemo(() => {
        if (allFilesFailed) {
            return <>Your data upload was not successful. Please check your data format and try again.</>
        }
        if (someFilesFailed) {
            return <>Your data upload was not successful. Please check your data format and try again.</>
        }
        if (allFilesSucceeded) {
            return <>Your data upload was successful. We are checking your files.</>
        }
        return <></>
    }, [allFilesFailed, allFilesSucceeded, someFilesFailed])


    return (
        <>
            {files.map((file: any, i: number) => (
                <div
                    key={i}
                    className="flex items-center justify-between mb-4 bg-Grey-background py-4 px-6 "
                >
                    {file.name}

                    <div className="flex items-center">

                        {isFileFailed(file) ?
                            <UploadResultWidget
                                tooltipContent={<div className="p-2">
                                    <span className="font-bold">{t("manual-upload.file-failed-tooltip.info")}</span>
                                    <ul className="list-disc pl-2">
                                        <li>{t("manual-upload.file-failed-tooltip.issue1")}</li>
                                        <li>{t("manual-upload.file-failed-tooltip.issue2")}</li>
                                    </ul>
                                </div>}
                                className="mr-4 text-Red-default bg-Red-default"
                                text={t("manual-upload.file-failed")} />
                            : null}
                        {successfulFiles.includes(file.code) ?
                            <UploadResultWidget
                                className="mr-0 text-Green-default bg-Green-default"
                                text={t("manual-upload.file-successfull")}
                                tooltipContent={<div className="p-2">
                                    <span className="font-bold">{t("manual-upload.file-successfull-tooltip.info")}</span>
                                </div>}
                            />
                            : null}
                        <>
                            {
                                isFileSuccessful(file) || uploading ? null : <DeleteFileIcon
                                    className="cursor-pointer"
                                    onClick={() => removeFile(i)}
                                />
                            }
                        </>

                    </div>
                </div>
            ))}

            {
                allFilesFailed || allFilesSucceeded || someFilesFailed ?

                    <EventBanner
                        state={allFilesFailed || someFilesFailed ? EventState.ERROR : EventState.SUCCESS}
                        content={bannerText}
                    />

                    : null
            }
        </>
    );
}
