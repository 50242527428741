import { ReactElement, createContext, useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useScreenSize } from "../../../../../electrify_frontend_common/hooks/useScreenSize";
import { useUserData } from "../../../../../hooks/state/useUserData";
import { getEvs, getFilters, patchFilters } from "../../../../../electrify_frontend_common/services";
import { toggleFavoriteStateForVehicle } from "../../../../../services/rest/electrificationPlanner";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { AlternativeVehicle, IFilters } from "../../../../../electrify_frontend_common/types";



export const alternativesSortMap: {
    label: string;
    key: string;
    order: "ASC" | "DESC";
}[] = [
        {
            label: "listingPrice",
            key: "listPrice",
            order: "ASC",
        },
        {
            label: "rangePerPrice",
            key: "rangeMetersPerDePriceK",
            order: "DESC"
        },
        {
            label: "realRange",
            key: "range.real.average",
            order: "DESC",
        },
        {
            label: "realConsumption",
            key: "testedConsumption100km.real",
            order: "ASC",
        },
        {
            label: "make",
            key: "modelInfo.make",
            order: "ASC",
        },
        {
            label: "loadVolume",
            key: "physicalInfo.volume.maximum",
            order: "DESC",
        },

    ];



export const FreeEvFinderContext = createContext<{
    filters: IFilters,
    updateFilters: ((filters: Partial<IFilters>) => void),
    sortBy: {
        label: string;
        key: string;
        order: "ASC" | "DESC";
    },
    updateSortBy: (sortBy: {
        label: string;
        key: string;
        order: "ASC" | "DESC";
    }) => void,
    page: number,
    setPage: ((page: number) => void),
    evDetailsToCheck: string | null,
    setEvDetailsToCheck: ((evId: string | null) => void),
    refetchFavoriteVehicles: (() => void),
    totalPagesCount: number,
    totalVehiclesCount: number,
    vehiclesList: Partial<AlternativeVehicle>[],
    isLoading: boolean,
    favoriteVehiclesList?: Partial<AlternativeVehicle>[],
    favoriteVehiclesCount?: number,
    favoritesOnly?: boolean,
    setFavoritesOnly: ((favoritesOnly: boolean) => void),
    userCountryCode?: string,
    toggleFavorite: ((evId: string) => void),
    resetFilters: () => void,
    togglingFavoriteEvId?: string
}>({
    filters: {} as IFilters,
    updateFilters: () => null,
    sortBy: {} as any,
    updateSortBy: () => null,
    page: 1,
    setPage: () => null,
    evDetailsToCheck: null,
    setEvDetailsToCheck: ((evId: string | null) => null),
    refetchFavoriteVehicles: () => null,
    totalPagesCount: 0,
    totalVehiclesCount: 0,
    vehiclesList: [],
    isLoading: false,
    favoriteVehiclesList: [],
    favoritesOnly: false,
    setFavoritesOnly: () => null,
    userCountryCode: undefined,
    toggleFavorite: () => null,
    resetFilters: () => null,
    togglingFavoriteEvId: undefined
})


export function FreeEvFinderContextProvider({ children }: { children: ReactElement }) {

    const { t } = useTranslation("evFinder");

    const { isMobileView, isTabletView, widthIsLessThan1024 } = useScreenSize();

    const [evDetailsToCheck, setEvDetailsToCheck] = useState<string | null>(null);


    const PAGE_SIZE = useMemo(() => {
        return isMobileView ? 6 : 12;
    }, [isTabletView])


    const [filters, setFilters] = useState<IFilters>({
        brands: [],
        availabilityStatus: [],
        listPrice: { min: null, max: null },
        topSpeed: null,
        realRange: null,
        realConsumption: null,
        loadWeight: null,
        loadVolume: null,
        seats: null,
        "4x4": null,
        fastCharging: false,
        towing: false,
        plugAndCharge: false,
        vehicleToGrid: false,
        isofixAvailable: false,
        roofRails: false,
    });

    const [sortBy, setSortBy] = useState(alternativesSortMap[0]);
    const [page, setPage] = useState(1);
    const { user } = useUserData();
    const [favoritesOnly, setFavoritesOnly] = useState(false);

    const [vehiclesData, setVehiclesData] = useState<{
        vehiclesCount: number;
        vehiclesList: Partial<AlternativeVehicle>[]
    }>({
        vehiclesCount: 0,
        vehiclesList: []
    });


    const [togglingFavoriteEvId, setTogglingFavoriteEvId] = useState<string | undefined>();
    const [firstLoad, setFirstLoad] = useState(true);

    const userCountryCode: string = "DE"; // Should be taken from either the IP of user or the country set for the device of user in the future

    const updateFilters = (updatedProps: Partial<IFilters>) => {
        setPage(1);
        setFilters((currentFilters: IFilters) => {
            updateFiltersMutation.mutate({
                filters: {
                    ...currentFilters,
                    ...updatedProps,
                },
                sortBy
            });
            return {
                ...currentFilters,
                ...updatedProps,
            };
        });
    }
    const resetFilters = () => updateFilters({
        brands: [],
        availabilityStatus: null,
        listPrice: { min: null, max: null },
        topSpeed: null,
        realRange: null,
        realConsumption: null,
        loadWeight: null,
        loadVolume: null,
        seats: null,
        "4x4": null,
        fastCharging: false,
        towing: false,
        plugAndCharge: false,
        vehicleToGrid: false,
        isofixAvailable: false,
        roofRails: false,
    });

    const updateSortBy = (newSortBy: any) => {
        setPage(1);
        updateFiltersMutation.mutate({
            filters,
            sortBy: newSortBy
        });
        setSortBy(newSortBy);
    }

    const handleVehiclesFetchSuccess = (data: {
        page: number;
        pageSize: number;
        totalPagesCount: number;
        electricVehicles: any[];
        totalVehiclesCount: number;
        filters: IFilters;
        sortBy: { key: string; order: "ASC" | "DESC" };
    }) => {
        setFirstLoad(false);
        const vehicles = data?.electricVehicles;
        if (!firstLoad && vehicles?.length > 0) {
        };

        setPage(data?.page || 1);
        setVehiclesData({
            vehiclesCount: data?.totalVehiclesCount || 0,
            vehiclesList: vehicles
        })
        return;
    }

    const { data, isLoading } = useQuery(
        ["vehicles", page, filters, sortBy, favoritesOnly],
        () =>
            getEvs({
                sortBy,
                selectedFilters: filters,
                page,
                pageSize: PAGE_SIZE,
                userCountryCode: userCountryCode || "DE",
                userId: user?.sub,
            }),
        {
            onSuccess: (data) => handleVehiclesFetchSuccess({ ...data }),
        }
    );


    const { data: favoriteVehiclesData, refetch: refetchFavoriteVehicles } = useQuery(
        ["favoriteVehicles", sortBy, favoritesOnly],
        () =>
            getEvs({
                sortBy,
                userCountryCode: userCountryCode || "DE",
                favoritesOnly: true,
                userId: user?.sub,
            }),
        {
            onSuccess: () => {
                setTogglingFavoriteEvId(undefined);
            }
        }
    )

    useQuery(
        ["filters"],
        getFilters,
        {
            onSuccess: (data) => {
                setFilters(data.filters);
                console.log("Sort by: ", data.sortBy);
                setSortBy(alternativesSortMap.find((sort) => sort.key === data.sortBy.key) || alternativesSortMap[0]);
            }
        }

    )

    const updateFiltersMutation = useMutation(patchFilters);


    const favoriteToggleMutation = useMutation(toggleFavoriteStateForVehicle, {
        onSuccess: (data: { action: string }) => {
            refetchFavoriteVehicles();
            if (data.action === "removed") {
                toast.success(t("favorite.removedToast"));
            } else {
                toast.success(t("favorite.addedToast"));
            }

        },
        onError: () => {
            toast.error(t("favorite.errorToast"));
            setTogglingFavoriteEvId(undefined);
        }
    });

    const toggleFavorite = (evId: string) => {
        setTogglingFavoriteEvId(evId);
        favoriteToggleMutation.mutate(evId);
    }



    return (
        <FreeEvFinderContext.Provider value={{
            filters,
            updateFilters,
            sortBy,
            updateSortBy,
            page,
            setPage,
            evDetailsToCheck,
            setEvDetailsToCheck,
            refetchFavoriteVehicles,
            totalPagesCount: data?.totalPagesCount || 0,
            totalVehiclesCount: vehiclesData.vehiclesCount,
            vehiclesList: vehiclesData.vehiclesList,
            isLoading,
            favoriteVehiclesList: favoriteVehiclesData?.electricVehicles || [],
            favoriteVehiclesCount: favoriteVehiclesData?.totalVehiclesCount || 0,
            favoritesOnly,
            setFavoritesOnly,
            userCountryCode,
            toggleFavorite,
            resetFilters,
            togglingFavoriteEvId
        }}>
            {children}
        </FreeEvFinderContext.Provider>
    )

}