import { useTranslation } from "react-i18next";

import { FilesDisplay } from "./FilesDisplay";
import { useFilesManager } from "./useFilesManager";
import { ReactElement } from "react";
import { Spinner, SpinnerSize } from "../../../electrify_frontend_common/components/Spinner";
import { Button } from "../../../electrify_frontend_common/components/Buttonv2";
import { ButtonSize, ButtonVariant } from "../../../electrify_frontend_common/components/Buttonv2/types";


function FileInput({ onButtonClick, inputRef, acceptedExtensions, handleChange, dragActive, handleDrag, handleDrop }:
    { onButtonClick: () => void, inputRef: any, acceptedExtensions: string, handleChange: any, dragActive: boolean, handleDrag: any, handleDrop: any }) {

    const { t } = useTranslation("dataProviderUpload");

    return (
        <div className="flex w-full items-center border border-Grey-tint rounded mr-4">
            <button className="w-1/4 h-full text-sm cursor-pointer text-Grey-shade bg-Grey-background border-r border-Grey-tint rounded-l py-5 flex justify-center hover:text-white hover:bg-Grey-shade"
                onClick={onButtonClick}>
                {t("choose-files")}
            </button>
            <div id="form-file-upload" className={`py-5 justify-center flex w-full text-Grey-shade ${dragActive ? 'bg-Grey-default opacity-60 text-black' : ''} `} onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
                <input
                    ref={inputRef}
                    className={'hidden'}
                    accept={acceptedExtensions}
                    type="file" id="input-file-upload"
                    multiple={true}
                    onChange={handleChange}
                />
                <label className={`${dragActive ? 'animate-bounce ' : ''}`} id="label-file-upload" htmlFor="input-file-upload" >
                    <div>
                        {t("drag-and-drop")}
                    </div>
                </label>
                {dragActive && <div id="drag-file-element" className="absolute w-full h-full rounded top-0 right-0 bottom-0 left-0" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div>}
            </div>
        </div>
    )

}


// drag drop file component
// { onCancel, goToPage, goToPageText }: { onCancel: () => void, goToPage: () => void, goToPageText: string }
export function FileUploader({ successElement }: { successElement?: ReactElement }) {

    const { t } = useTranslation("dataProviderUpload");
    const {
        acceptedExtensions,
        inputRef,
        dragActive,
        files,
        failedFiles,
        uploadStatus,
        successfulFiles,
        uploading,
        handleSubmit,
        removeFile,
        handleDrag,
        handleDrop,
        handleChange,
        onButtonClick
    } = useFilesManager();



    return (<div className="flex flex-col w-full">

        <div className="flex  h-full">
            <FileInput
                onButtonClick={onButtonClick}
                inputRef={inputRef}
                acceptedExtensions={acceptedExtensions}
                handleChange={handleChange}
                dragActive={dragActive}
                handleDrag={handleDrag}
                handleDrop={handleDrop}
            />

            <Button disabled={uploading} variant={ButtonVariant.PRIMARY} size={ButtonSize.LARGE} onClick={handleSubmit}>
                <div className="flex items-center">
                    {uploading ? <div className="flex items-center">
                        <div className="mr-2"><Spinner size={SpinnerSize.SMALL} /></div>
                        {t("uploading")}
                    </div> : t("upload-button")}

                </div>
            </Button>
        </div>
        <div className="mt-4">
            <FilesDisplay files={files} removeFile={removeFile} failedFiles={failedFiles} successfulFiles={successfulFiles} uploading={uploading} />
        </div>
        {successElement && successfulFiles.length > 0 ? successElement : null}
    </div>
    );
};